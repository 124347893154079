import { Box, Stack } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Button, GridContainer, GridItem, Select, TextField } from '../shared';
import { LoadingButton } from '@mui/lab';
import useStyles from './style';

import { Percent } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { leaseSchema } from '../../schema/validationSchemas';
import { PerformantDecimalField } from '../shared/PerformantDecimalField/PerformantDecimalField';

const GeneralDetailsForm = (props) => {
  const {
    isUpdate = false,
    onAddClick = () => {},
    onBackClick = () => {},
    onCancelClick = () => {},
    onUpdateClick = () => {},
    isLoading = false,
  } = props;
  const classes = useStyles();

  const leaseState = useSelector((state) => state?.lease?.leaseForms);
  const { generalDetails } = leaseState;
  const formik = useFormik({
    initialValues: generalDetails,
    validationSchema: leaseSchema.generalDetailsSchema,
    onSubmit: (values) => {
      if (isUpdate) {
        onUpdateClick(values);
      } else {
        onAddClick(values);
      }
    },
  });
  const { touched, errors, getFieldProps, handleSubmit, setValues } = formik;

  useEffect(() => {
    setValues(generalDetails);
  }, [generalDetails]);

  const workersCompArr = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  const genLiabilityArr = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  const umbrellaArr = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  const terminationDaysArr = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
  ];

  const relocationDaysArr = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
  ];

  const addendumArr = [
    { value: true, text: 'Yes' },
    { value: false, text: 'No' },
  ];

  return (
    <FormikProvider value={formik}>
      <Box className={classes.generalDetailsForm} data-testid="generalDetailsComponent">
        <GridContainer rowSpacing={3} columnSpacing={{ xs: 0, sm: 0, md: 6 }} pb={4}>
          <GridItem xs={12} md={4} pl={0}>
            <Select
              inputLabel="Workers Comp (s/b $1million)"
              options={workersCompArr}
              {...getFieldProps('workersComp')}
              data-testid="generalDetailsForm"
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <Select
              inputLabel="Gen Liability (s/b $2million)"
              options={genLiabilityArr}
              {...getFieldProps('genLiability')}
              data-testid="generalDetailsForm"
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <Select
              inputLabel="Umbrella (s/b $5million)"
              options={umbrellaArr}
              {...getFieldProps('umbrella')}
              data-testid="generalDetailsForm"
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <Select
              inputLabel="Termination Days"
              options={terminationDaysArr}
              {...getFieldProps('terminationDays')}
              data-testid="generalDetailsForm"
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <Select
              inputLabel="Relocation Days"
              options={relocationDaysArr}
              {...getFieldProps('relocationDays')}
              data-testid="generalDetailsForm"
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <TextField
              label="Sales Report By"
              {...getFieldProps('salesReportsBy')}
              InputProps={{
                'data-testid': 'generalDetailsForm',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <TextField
              label="Percent Rent By"
              {...getFieldProps('percentRentBy')}
              InputProps={{
                'data-testid': 'generalDetailsForm',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <TextField
              label="Fixed Rent Due"
              {...getFieldProps('fixedRentDue')}
              InputProps={{
                'data-testid': 'generalDetailsForm',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <Select
              inputLabel="Addendum"
              options={addendumArr}
              {...getFieldProps('addendum')}
              data-testid="generalDetailsForm"
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <PerformantDecimalField
              useFormik={true}
              priceIcon={true}
              label="Security Deposit"
              {...getFieldProps('securityDeposit')}
              error={Boolean(touched.securityDeposit && errors.securityDeposit)}
              helperText={touched.securityDeposit && errors.securityDeposit}
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <PerformantDecimalField
              useFormik={true}
              priceIcon={true}
              label="Gross Sales"
              {...getFieldProps('grossSales')}
              error={Boolean(touched.grossSales && errors.grossSales)}
              helperText={touched.grossSales && errors.grossSales}
            />
          </GridItem>
          <GridItem xs={12} md={4} pl={0}>
            <TextField
              label="OCC %"
              type="number"
              {...getFieldProps('OCC')}
              InputProps={{
                'data-testid': 'generalDetailsForm',
                startAdornment: <Percent fontSize="small" />,
              }}
              error={Boolean(touched.OCC && errors.OCC)}
              helperText={touched.OCC && errors.OCC}
            />
          </GridItem>
        </GridContainer>
        <Box my={1}>
          <Stack direction="row" data-testid="generalDetailsBtnWrapper">
            <LoadingButton loading={isLoading} variant="contained" data-testid="submitBtn" onClick={handleSubmit}>
              {isUpdate ? 'Update' : 'Add'}
            </LoadingButton>
            <Button onClick={isUpdate ? onCancelClick : onBackClick} data-testid="backBtn">
              {isUpdate ? 'Cancel' : 'Back'}
            </Button>
          </Stack>
        </Box>
      </Box>
    </FormikProvider>
  );
};

export default GeneralDetailsForm;
