import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { CURRENCY_CONSTANT, CURRENCY_TYPE } from '../../../constants/CurrencyConstants';
import { getCurrency } from '../../../utils/common-methods';
import ErrorEndAdornment from '../../ErrorEndAdornment';
import { NEGATIVE_VALUE_ALLOW_FIELD_LABELS } from '../../../constants/MeterTypeFieldMap';

const displayName = 'PerformantDecimalField';

export const PerformantDecimalField = React.memo((props) => {
  const {
    useFormik = false, // boolean prop to indicate Formik integration
    disablePerformance,
    label,
    currencyType = CURRENCY_TYPE.USD,
    priceIcon,
    showWarning,
    warningMessage,
    loading,
    name,
    error,
    helperText,
    disabled,
    value: propValue,
    onChange,
    onBlur: propOnBlur,
    onPaste: propOnPaste,
    ...otherProps
  } = props;
  let field, meta, formikContext;

  // Use Formik context and field only if `useFormik` is true
  if (useFormik && name) {
    formikContext = useFormikContext();
    [field, meta] = useField(name);
  }
  const initialValue = useFormik && field ? field.value ?? '0.00' : propValue ?? '0.00';
  const [currentFieldValue, setCurrentFieldValue] = useState(() =>
    !isNaN(initialValue) && initialValue !== '' ? parseFloat(initialValue).toFixed(2) : '0.00',
  );
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (useFormik && formikContext && meta && !meta.touched) {
      if (parseFloat(field.value).toFixed(2) !== parseFloat(currentFieldValue).toFixed(2)) {
        const fieldValue =
          !isNaN(field.value) && field.value !== '' && field.value !== null
            ? parseFloat(field.value).toFixed(2)
            : '0.00';
        setCurrentFieldValue(fieldValue ?? '0.00');
      }
    } else if (parseFloat(propValue).toFixed(2) !== currentFieldValue) {
      setCurrentFieldValue(propValue ?? '0.00');
    }
  }, [useFormik, field?.value, propValue, formikContext, meta]);

  const handleChange = (evt) => {
    let inputValue = evt.target.value.replace(/[^0-9.-]/g, '');
    if (inputValue.startsWith('.')) {
      inputValue = '0' + inputValue;
    }
    inputValue = inputValue.replace(/-+/g, '-').replace(/^-{2,}/, '-');
    const decimalParts = inputValue.split('.');
    if (decimalParts.length > 2) {
      inputValue = decimalParts[0] + '.' + decimalParts[1];
    }

    setCurrentFieldValue(inputValue);
    if (useFormik && formikContext && field) {
      // Use Formik's `setFieldValue` for updating the field.
      formikContext.setFieldValue(name, inputValue);
    }

    // Call the provided onChange callback if it exists
    evt.target.value = inputValue;
    onChange?.(evt);
  };

  const handleBlur = (evt) => {
    let val = evt.target.value || '';
    if (val && !val.includes('.')) {
      val = `${val}.00`;
    }
    if (val && val.includes('.')) {
      const [integer, decimal] = val.split('.');
      val = `${integer}.${(decimal + '00').slice(0, 2)}`;
    }
    setCurrentFieldValue(val);
    if (useFormik && field) {
      field.onBlur(evt);
      if (formikContext && String(formikContext.values[name]) !== String(val)) {
        field.onChange({
          target: {
            name: name,
            value: val,
          },
        });
      }
    } else {
      propOnBlur?.(evt);
    }
  };

  const handlePaste = (evt) => {
    const pasteData = evt.clipboardData.getData('text');
    let sanitizedData = pasteData.replace(/[^0-9.-]/g, '');
    const isValidNumber = !isNaN(sanitizedData) && sanitizedData !== '';
    if (!isValidNumber) {
      sanitizedData = '00.00';
    }
    evt.preventDefault();
    setCurrentFieldValue(parseFloat(sanitizedData).toFixed(2));
    if (useFormik && formikContext && field) {
      // Use Formik's `setFieldValue` for updating the field.
      formikContext.setFieldValue(name, sanitizedData);
    }
    propOnPaste?.(evt);
  };

  const performanceProps = disablePerformance
    ? {
        value: loading ? 'Loading...' : currentFieldValue,
      }
    : {
        value: loading ? 'Loading...' : currentFieldValue,
        onChange: handleChange,
        onPaste: handlePaste,
        onBlur: (evt) => {
          setIsFocus(false);
          handleBlur(evt);
        },
        onFocus: () => {
          setIsFocus(true);
        },
      };

  return (
    <>
      <TextField
        {...otherProps}
        label={typeof label === 'string' ? label : 'Label'}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        onPaste={propOnPaste}
        value={currentFieldValue}
        type="text"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {priceIcon ? (currencyType === CURRENCY_TYPE.USD ? getCurrency(CURRENCY_CONSTANT.USD) : '') : ''}
            </InputAdornment>
          ),
          endAdornment: !isFocus && showWarning ? <ErrorEndAdornment message={warningMessage} /> : '',
          ...((props.type === 'number' && {
            inputProps: {
              min: props?.min && !NEGATIVE_VALUE_ALLOW_FIELD_LABELS.includes(label) ? 0 : null,
              max: props?.max,
              step: 'any',
            },
          }) ||
            undefined),
        }}
        error={error}
        helperText={helperText || (meta?.error ? meta.error : '')}
        {...performanceProps}
        disabled={disabled}
      />
    </>
  );
});

PerformantDecimalField.displayName = displayName;
