import React from 'react';
import { GridContainer, GridItem, InformationField } from '../../../components/shared';
import useStyles from '../style';
import { formatNumberOrReturnZero } from '../../../utils/common-methods';

const SublocationSummaryField = ({ content }) => {
  const classes = useStyles();

  return (
    <GridContainer
      className={classes.summaryBackground}
      data-testid="summary"
      spacing={2}
      px={3}
      sx={{ width: '100%' }}
    >
      {content &&
        content?.length > 0 &&
        content.map((element, index) => {
          const { label, value, priceIcon } = element;
          return (
            <React.Fragment key={index}>
              <GridItem xs={6} lg={3}>
                <InformationField data-testid="revenueSummary" value={label} variant="h3" />
              </GridItem>
              <GridItem xs={6} lg={9} display="flex" justifyContent="flex-end" alignItems="flex-end">
                <InformationField
                  value={priceIcon ? `${priceIcon} ${formatNumberOrReturnZero(value)}` : value}
                  variant="subtitle1"
                />
              </GridItem>
            </React.Fragment>
          );
        })}
    </GridContainer>
  );
};

export default SublocationSummaryField;
