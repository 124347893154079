import React, { useState } from 'react';
import { InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { GridContainer, GridItem, TextField } from '../../../components/shared';
import {
  checkIsNegativeZero,
  formatNumberOrReturnZero,
  getCurrency,
  replaceStartValueZeroWithNumber,
  setPlayCardSummaryValToDecimal,
} from '../../../utils/common-methods';
import { setPlayCardSalesSummary, setPlayCards, setPlaycardFormDirty } from '../../../redux-slice/playcardsSummary';
import useStyles from '../style';
import { MESSAGE } from '../../../constants/message';
import {
  getAdjustedPlaycardSales,
  getNetCashTotal,
  getNetCreditCardTotal,
  getPlayCardSalesRevenueInUsd,
} from '../../../utils/collection-methods';
import { CURRENCY_CONSTANT } from '../../../constants/CurrencyConstants';
import { useEffect } from 'react';
import { ZERO_VALUE } from '../../../constants/CommonConstants';
import { setDefaultAccountSummaryState } from '../../../redux-slice/reconciliationCollection';
import { PerformantDecimalField } from '../../../components/shared/PerformantDecimalField/PerformantDecimalField';

const PlayCardSummary = ({ sublocation, isApproved = false, readFromDraft, skip = true }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { playCards } = useSelector((state) => state.playCard);
  const { accountSummary } = useSelector((state) => state.reconciliationCollection);
  const theme = useTheme();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const [currentTextValue, setCurrentTextValue] = useState({});

  useEffect(() => {
    // If read from draft, update with latest playCards data stored in redux
    if (readFromDraft) {
      setCurrentTextValue(playCards[sublocation?.id]);
    }
    if (!skip) {
      setCurrentTextValue(playCards[sublocation?.id]);
    }
  }, [readFromDraft, playCards]);

  useEffect(() => {
    let obj = { ...playCards[sublocation?.id] };
    obj.adjustments = obj?.adjustments || 0;
    setCurrentTextValue(obj);
  }, []);

  const onChange = (e, id) => {
    if (accountSummary?.defaultAccountSummaryState) {
      dispatch(setDefaultAccountSummaryState(false));
    }
    const { name, value } = e.target;
    const replaceZeroValueAtStart = replaceStartValueZeroWithNumber(value);
    const accessCurrentPlayCardObj = currentTextValue;
    const changeValueOfPlayCardObj = {
      ...accessCurrentPlayCardObj,
      [name]: replaceZeroValueAtStart,
    };
    changeValueOfPlayCardObj.netCashTotal = getNetCashTotal(changeValueOfPlayCardObj);
    changeValueOfPlayCardObj.netCreditCardTotal = getNetCreditCardTotal(changeValueOfPlayCardObj);
    changeValueOfPlayCardObj.adjustedPlaycardSales = getAdjustedPlaycardSales(changeValueOfPlayCardObj);
    const finalObjValues = { ...changeValueOfPlayCardObj };
    setCurrentTextValue(finalObjValues);
    const latestPlayCardData = { ...playCards, [id]: finalObjValues };
    const totalPlayCardSales = getPlayCardSalesRevenueInUsd(latestPlayCardData);
    setTimeout(() => {
      dispatch(setPlayCardSalesSummary(totalPlayCardSales));
    }, 800);
  };

  const onBlur = (e) => {
    const { name, value } = e.target;
    if (value) {
      let val = value || '';
      if (val && !val.includes('.')) {
        val = `${val}.00`;
      }
      if (val && val.includes('.')) {
        const [integer, decimal] = val.split('.');
        val = `${integer}.${(decimal + '00').slice(0, 2)}`;
      }

      e.target.value = val;

      const values = setPlayCardSummaryValToDecimal(currentTextValue);
      setCurrentTextValue(values);
    }
    if (!value || checkIsNegativeZero(value)) {
      const accessCurrentPlayCardObj = currentTextValue;
      const changeValueOfPlayCardObj = {
        ...accessCurrentPlayCardObj,
        [name]: 0,
      };
      changeValueOfPlayCardObj.netCashTotal = getNetCashTotal(changeValueOfPlayCardObj);
      changeValueOfPlayCardObj.netCreditCardTotal = getNetCreditCardTotal(changeValueOfPlayCardObj);
      changeValueOfPlayCardObj.adjustedPlaycardSales = getAdjustedPlaycardSales(changeValueOfPlayCardObj);
      const finalObjValues = { ...changeValueOfPlayCardObj };
      setCurrentTextValue(finalObjValues);
    }
    setTimeout(() => {
      dispatch(setPlaycardFormDirty(true));
      dispatch(setPlayCards({ ...playCards, [sublocation?.id]: setPlayCardSummaryValToDecimal(currentTextValue) }));
    }, 200);
  };
  const cashPlayCardSales = currentTextValue?.cashPlaycardSales;
  const cashRefunds = currentTextValue?.cashRefunds;
  const netCashTotal = currentTextValue?.netCashTotal;
  const salesFromCreditCard = currentTextValue?.salesFromCreditCard;
  const creditCardRefunds = currentTextValue?.creditCardRefunds;
  const netCreditCardTotal = currentTextValue?.netCreditCardTotal;
  const complimentaryPlaycards = currentTextValue?.complimentaryPlaycards;
  const bulkEncodedPlaycards = currentTextValue?.bulkEncodedPlaycards;
  const adjustments = currentTextValue?.adjustments;
  const adjustedPlaycardSales = currentTextValue?.adjustedPlaycardSales;
  return (
    <GridContainer className={classes.collectionFieldItem} spacing={1}>
      <GridItem xs={12} className={classes.playcardTitleContainer}>
        <Typography variant="subtitle9">{'Playcard Sales Summary'}</Typography>
      </GridItem>
      <GridItem className={isBelowMD ? classes.playcardMeterTotalSales : classes.playcardSaleFromCash} xs={12} md={6}>
        <GridContainer spacing={1}>
          <GridItem xs={12} pb={2}>
            <Typography variant="subtitle4">Playcard Sales From Cash</Typography>
          </GridItem>
          <GridItem xs={12} sm={4} pb={1}>
            <TextField
              type="number"
              label={'Playcard Sales'}
              name={'cashPlaycardSales'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={cashPlayCardSales || ZERO_VALUE}
              onChange={(e) => onChange(e, sublocation?.id)}
              onBlur={onBlur}
              restrictNegativeValue={true}
              disabled={isApproved}
            />
          </GridItem>
          <GridItem xs={12} sm={4} pb={1}>
            <TextField
              type="number"
              label={'Cash Refunds'}
              name={'cashRefunds'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={cashRefunds || ZERO_VALUE}
              error={Boolean(Number(cashPlayCardSales) < Number(cashRefunds))}
              helperText={Number(cashPlayCardSales) < Number(cashRefunds) ? MESSAGE.REFUND : ''}
              onChange={(e) => onChange(e, sublocation?.id)}
              onBlur={onBlur}
              restrictNegativeValue={true}
              disabled={isApproved}
            />
          </GridItem>
          <GridItem xs={12} sm={4} pb={1}>
            <PerformantDecimalField
              useFormik={false}
              priceIcon={true}
              label={'Net Cash Total'}
              name={'netCashTotal'}
              value={formatNumberOrReturnZero(netCashTotal) || ZERO_VALUE}
              disabled
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={isBelowMD ? classes.playcardMeterTotalSales : classes.playcardMeterTitle} xs={12} md={6}>
        <GridContainer spacing={1} className={classes.playcardLabel}>
          <GridItem xs={12} pb={2}>
            <Typography variant="subtitle4">Playcard Sales From Credit Card</Typography>
          </GridItem>
          <GridItem xs={12} sm={4} pb={1} className={classes.labelClass}>
            <TextField
              type="number"
              label={'Sales From Credit Card'}
              name={'salesFromCreditCard'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={salesFromCreditCard || ZERO_VALUE}
              onChange={(e) => onChange(e, sublocation?.id)}
              onBlur={onBlur}
              restrictNegativeValue={true}
              disabled={isApproved}
            />
          </GridItem>
          <GridItem xs={12} sm={4} pb={1}>
            <TextField
              type="number"
              label={'Refunds'}
              name={'creditCardRefunds'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={creditCardRefunds || ZERO_VALUE}
              onChange={(e) => onChange(e, sublocation?.id)}
              onBlur={onBlur}
              error={Boolean(Number(salesFromCreditCard) < Number(creditCardRefunds))}
              helperText={Number(salesFromCreditCard) < Number(creditCardRefunds) ? MESSAGE.REFUND_CREDIT_CARD : ''}
              restrictNegativeValue={true}
              disabled={isApproved}
            />
          </GridItem>
          <GridItem xs={12} sm={4} pb={1}>
            <TextField
              type="number"
              label={'Net Credit Card Total'}
              name={'netCreditCardTotal'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              restrictNegativeValue={true}
              value={formatNumberOrReturnZero(netCreditCardTotal) || ZERO_VALUE}
              disabled
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.playcardMeterTotalSales} xs={12}>
        <GridContainer spacing={1}>
          <GridItem xs={12} pb={1}>
            <Typography variant="subtitle4">Total Playcard Sales</Typography>
          </GridItem>
          <GridItem xs={12} sm={3} pb={1}>
            <TextField
              type="number"
              label={'Complimentary Playcards'}
              name={'complimentaryPlaycards'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={complimentaryPlaycards || ZERO_VALUE}
              onChange={(e) => onChange(e, sublocation?.id)}
              onBlur={onBlur}
              restrictNegativeValue={true}
              disabled={isApproved}
            />
          </GridItem>
          <GridItem xs={12} sm={3} pb={1}>
            <TextField
              type="number"
              label={'Bulk Encoded Playcards'}
              name={'bulkEncodedPlaycards'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={bulkEncodedPlaycards || ZERO_VALUE}
              onChange={(e) => onChange(e, sublocation?.id)}
              onBlur={onBlur}
              restrictNegativeValue={true}
              disabled={isApproved}
            />
          </GridItem>
          <GridItem xs={12} sm={3} pb={1}>
            <TextField
              type="number"
              label={'Adjustments(3rd Party,Misc etc'}
              name={'adjustments'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={adjustments}
              onChange={(e) => onChange(e, sublocation?.id)}
              onBlur={(e) => onBlur(e)}
              disabled={isApproved}
            />
          </GridItem>
          <GridItem xs={12} sm={3} pb={1}>
            <TextField
              type="number"
              label={'Adjusted Playcard Sales'}
              name={'adjustedPlaycardSales'}
              InputProps={{
                startAdornment: <InputAdornment position="start">{getCurrency(CURRENCY_CONSTANT.USD)}</InputAdornment>,
              }}
              value={formatNumberOrReturnZero(adjustedPlaycardSales) || ZERO_VALUE}
              disabled
              restrictNegativeValue={true}
              helperText={adjustedPlaycardSales < 0 ? MESSAGE.NEGATIVE_ADJUST_PLAYCARD : ''}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default PlayCardSummary;
