import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  leaseForms: {
    leaseDetails: {
      leaseName: '',
      location: null,
      sublocationsOfLocation: [],
      subLocation: [],
      city: '',
      state: '',
      zipCode: '',
      address1: '',
      address2: '',
      country: '',
      boothInfo: '',
      space: '',
      dba: '',
      salesPerson: '',
      leaseType: '',
      status: '',
      leaseBegins: null,
      leaseEnds: null,
    },
    rentDetails: {
      rentType: 'RATE_PLUS_FEE',
      rentFee: 0.0,
      rentCalculationTiming: '',
      rentRate: 0.0,
      calculationStarts: null,
      calculationEnds: null,
      breakpointNatural: true,
      breakpointValue: 0.0,
      CAMFee: 0.0,
      CAMRate: '',
      modalTypes: [],
      thresholdRate: 0,
      thresholdRevenue: 0.0,
      notes: '',
      coinCost: 0.0,
      cardCost: 0.0,
    },
    generalDetails: {
      workersComp: '',
      genLiability: '',
      umbrella: '',
      terminationDays: '',
      relocationDays: '',
      salesReportsBy: '',
      percentRentBy: '',
      fixedRentDue: '',
      addendum: '',
      securityDeposit: '',
      grossSales: '',
      OCC: '',
    },
  },
  leases: {
    data: [],
    pagination: {
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      total: 0,
      pages: 100,
    },
    isLoading: false,
    isError: false,
    isFetching: false,
  },
  searchApiParams: 'processStatus=&locationId=&leaseStatus=PENDING',
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PER_PAGE,
};

export const leaseSlice = createSlice({
  name: 'lease',
  initialState,
  reducers: {
    leaseDetailsFormAction(state, action) {
      state.leaseForms.leaseDetails = action.payload;
    },
    rentDetailsFormAction(state, action) {
      state.leaseForms.rentDetails = action.payload;
    },
    generalDetailsFormAction(state, action) {
      state.leaseForms.generalDetails = action.payload;
    },
    setSearchApiParamsAction(state, action) {
      state.searchApiParams = action.payload;
    },
    setLeasesAction(state, action) {
      state.leases = action.payload;
    },
    setPageAction(state, action) {
      state.page = action.payload;
    },
    setPerPageAction(state, action) {
      state.perPage = action.payload;
    },
    resetLeaseAction(state) {
      Object.assign(state, initialState);
    },
  },
});

export default leaseSlice;
