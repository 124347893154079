import React, { useState, useMemo, useEffect } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import useStyles from './style';

import { routeConstants } from '../../../constants/routeConstants';
import {
  Breadcrumbs,
  Button,
  GridContainer,
  GridItem,
  Select,
  TextField,
  Autocomplete,
} from '../../../components/shared';
import {
  useGetAllCountriesQuery,
  useLazyGetCountryStatesByCountryIdQuery,
  useLazyGetStateCitiesByStateIdQuery,
} from '../../../services/CountriesAndStatesAndCities/CountriesAndStatesAndCities';
import {
  useGetUserByIdQuery,
  useGetUsersQuery,
  useLazyCheckSUCodeQuery,
  useLazyLoginUserQuery,
  useUpdateUserByIdMutation,
} from '../../../services/Users/UsersService';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserRolesQuery } from '../../../services/UserRoles/UserRolesService';
import {
  getPhNo10DigitsWithCountryCode,
  isArrayWithLength,
  formatPhNo10Digit,
  getUserName,
} from '../../../utils/common-methods';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { editUserSchema } from '../../../schema/validationSchemas';
import { useLazyGetUserUiInfoQuery } from '../../../services/UI/Ui';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { ROLE } from '../../../constants/roles';
import { FILTER_ACCOUNTING_ROLE, FILTER_REGINAL_MANAGER_ROLE } from '../../../constants/FilterConstants';

const displayName = 'Edit-User';

const USER_STATE = {
  ID: 'id',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE: 'phone',
  JOINING_DATE: 'joiningDate',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  MANAGEMENT_ROLE: 'managementRole',
  COLLECTION_ROLE: 'collectionRole',
  COUNTRY: 'country',
  CITY: 'city',
  STATE: 'state',
  STATUS: 'status',
  REGIONAL_MANAGER: 'regionalManager',
  ACCOUNTING_USER: 'accountingUser',
  SU_CODE: 'suCode',
};

const EditUser = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.user);
  const {
    USER: { USERS, USER_DETAILS, EDIT_USERS },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = useMemo(
    () => [
      { text: USERS, redirection: routeConstants.USERS_ROUTE },
      {
        text: USER_DETAILS,
        redirection: `${routeConstants.USER_ROUTE}/${params.id}`,
      },
      { text: EDIT_USERS },
    ],
    [],
  );
  const [isEmployeeIndependentUser, setIsEmployeeIndependentUser] = useState(false);
  const [suCodeErrorMessage, setSUCodeErrorMessage] = useState('');
  const [verifySUCode] = useLazyCheckSUCodeQuery();
  const formik = useFormik({
    initialValues: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      joiningDate: '',
      address1: '',
      address2: '',
      managementRole: '',
      collectionRole: [],
      country: '',
      state: '',
      city: '',
      status: false,
      regionalManager: '',
      accountingUser: '',
      suCode: '',
    },
    validationSchema: editUserSchema,
    onSubmit: (data) => {
      setSUCodeErrorMessage('');
      const updateUserData = {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        phone1: getPhNo10DigitsWithCountryCode(data?.phone) ?? null,
        address1: data.address1,
        address2: data.address2,
        cityId: data.city,
        stateId: data.state,
        countryId: data.country,
        roles: data.managementRole
          ? [data.managementRole].concat(data.collectionRole.map((item) => item.id)).join(',')
          : data.collectionRole.map((item) => item.id).join(','),
        regionalManagerId: data?.regionalManager?.value ? data?.regionalManager?.value : null,
        accountingUserId: data?.accountingUser?.value ? data?.accountingUser?.value : null,
        suCode: data?.suCode || null,
      };
      if (isSUCodeUpdated) {
        checkUniquSUCode(data, updateUserData);
      } else {
        editUserData(data, updateUserData);
      }
    },
  });

  const editUserData = async (data, updateUserData) => {
    updateUserApi(updateUserData).then(async (response) => {
      if (response.data) {
        if (data.id == userDetails.id) {
          await getCurrentUser();
          await getUpdatedUI();
        }
        navigate(`/${routeConstants.USER_ROUTE}/${data.id}`);
      }
    });
  };

  const checkUniquSUCode = (currentData, updateUserData) => {
    verifySUCode({
      userId: updateUserData.id,
      suCode: updateUserData.suCode,
    })
      .then((response) => {
        if (response.data.isAlreadyAssigned) {
          setSUCodeErrorMessage(`SU Code already assigned to ${response?.data?.assignedToUser?.[0]?.user?.email}`);
        } else {
          // Proceed to update user data if SU code is unique
          setSUCodeErrorMessage('');
          editUserData(currentData, updateUserData);
        }
      })
      .catch((error) => {
        // Handle error
        console.log(error);
      });
  };
  const { currentData: userDetailResponse, isSuccess } = useGetUserByIdQuery(Number(params.id));
  const { currentData: countriesData, isSuccess: countriesAPISuccess } = useGetAllCountriesQuery();
  const { currentData: rolesResponse, isSuccess: rolesSuccess } = useGetUserRolesQuery();
  const [getCitiesByStateId, citiesResponseByStateId] = useLazyGetStateCitiesByStateIdQuery();
  const [getStatesByCountryId, statesResponseByCountryId] = useLazyGetCountryStatesByCountryIdQuery();
  const [updateUserApi] = useUpdateUserByIdMutation();
  const [getCurrentUser] = useLazyLoginUserQuery();
  const [getUpdatedUI] = useLazyGetUserUiInfoQuery();
  const initialSUCode = userDetailResponse?.data?.vendorUser?.vendorCode || '';
  const isSUCodeUpdated = useMemo(() => initialSUCode !== formik.values.suCode, [initialSUCode, formik.values.suCode]);

  const { currentData: acUsers, isSuccess: acUsersAPISuccess } = useGetUsersQuery({
    searchParams: `role=${FILTER_ACCOUNTING_ROLE}`,
    perPage: 100,
    page: 1,
  });
  const { currentData: rmUsers, isSuccess: rmUsersAPISuccess } = useGetUsersQuery({
    searchParams: `role=${FILTER_REGINAL_MANAGER_ROLE}`,
    perPage: 100,
    page: 1,
  });
  const [managementRoles, setManagementRoles] = useState([]);
  const [collectionRoles, setCollectionRoles] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [acUsersData, setAcUsersData] = useState([]);
  const [rmUsersData, setRmUsersData] = useState([]);

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (rolesSuccess) {
      const { managementRoles, roles } = rolesResponse.data;
      setManagementRoles([
        { value: '', text: 'None' },
        ...managementRoles.map((item) => ({
          value: item.id,
          text: item.title,
        })),
      ]);
      setCollectionRoles(
        roles.map((item) => ({
          id: item.id,
          value: item.id,
          name: item.title,
        })),
      );
    }
  }, [rolesResponse]);

  useEffect(() => {
    if (countriesAPISuccess) {
      setCountryData(countriesData.map((item) => ({ value: item.id, text: item.name })) || []);
    }
  }, [countriesData]);

  useEffect(() => {
    if (statesResponseByCountryId.isSuccess) {
      setStatesData(
        statesResponseByCountryId.currentData?.data?.states?.map((item) => ({
          value: item.id,
          text: item.name,
        })) || [],
      );
    }
  }, [statesResponseByCountryId.currentData]);

  useEffect(() => {
    if (citiesResponseByStateId.isSuccess) {
      setCitiesData(
        citiesResponseByStateId.currentData?.data?.cities?.map((item) => ({
          value: item.id,
          text: item.name,
        })) || [],
      );
    }
  }, [citiesResponseByStateId.currentData]);

  useEffect(() => {
    if (acUsersAPISuccess) {
      setAcUsersData(
        acUsers.users?.map((item) => ({
          value: item.id,
          text: getUserName(item),
        })) || [],
      );
    }
  }, [acUsers, acUsersAPISuccess]);

  useEffect(() => {
    if (rmUsersAPISuccess) {
      setRmUsersData(
        rmUsers.users?.map((item) => ({
          value: item.id,
          text: getUserName(item),
        })) || [],
      );
    }
  }, [rmUsers, rmUsersAPISuccess]);

  useEffect(() => {
    if (isSuccess) {
      formik.setFieldValue(USER_STATE.ID, userDetailResponse?.data?.id);
      formik.setFieldValue(USER_STATE.FIRST_NAME, userDetailResponse?.data?.firstName || '');
      formik.setFieldValue(USER_STATE.LAST_NAME, userDetailResponse?.data?.lastName || '');
      formik.setFieldValue(USER_STATE.EMAIL, userDetailResponse?.data?.email || '');
      formik.setFieldValue(USER_STATE.PHONE, formatPhNo10Digit(userDetailResponse?.data?.phone1) || '');
      formik.setFieldValue(USER_STATE.JOINING_DATE, userDetailResponse?.data?.hireDate || '');
      formik.setFieldValue(USER_STATE.ADDRESS1, userDetailResponse?.data?.address1 || '');
      formik.setFieldValue(USER_STATE.ADDRESS2, userDetailResponse?.data?.address2 || '');
      formik.setFieldValue(USER_STATE.COUNTRY, userDetailResponse?.data?.countryId || '');
      formik.setFieldValue(USER_STATE.CITY, userDetailResponse?.data?.cityId || '');
      formik.setFieldValue(USER_STATE.STATE, userDetailResponse?.data?.stateId || '');
      formik.setFieldValue(USER_STATE.STATUS, userDetailResponse?.data?.status || false);
      formik.setFieldValue(USER_STATE.SU_CODE, userDetailResponse?.data?.vendorUser?.vendorCode || '');
      const userRegionalManager = userDetailResponse?.data?.regionalManager
        ? {
            text: getUserName(userDetailResponse?.data?.regionalManager),
            id: userDetailResponse?.data?.regionalManager?.id,
          }
        : {};

      const userAc = userDetailResponse?.data?.accountingUser
        ? {
            text: getUserName(userDetailResponse?.data?.accountingUser),
            id: userDetailResponse?.data?.accountingUser?.id,
          }
        : {};
      formik.setFieldValue(USER_STATE.REGIONAL_MANAGER, userRegionalManager);
      formik.setFieldValue(USER_STATE.ACCOUNTING_USER, userAc);
      if (userDetailResponse?.data?.roles) {
        const { managementRole, nmRoles } = userDetailResponse.data.roles;
        formik.setFieldValue(USER_STATE.MANAGEMENT_ROLE, managementRole[0]?.id || '');
        formik.setFieldValue(
          USER_STATE.COLLECTION_ROLE,
          isArrayWithLength(nmRoles)
            ? nmRoles.map((item) => ({
                id: item.id,
                value: item.id,
                name: item.title,
              }))
            : [],
        );
      }
      const checkIsEmployeeIndependentUser = Boolean(
        userDetailResponse?.data?.roles?.managementRole?.find((el) => el?.title === ROLE.INDEPENDENT_REPS),
      );
      setIsEmployeeIndependentUser(checkIsEmployeeIndependentUser);
    }
    if (userDetailResponse?.data?.countryId) {
      getStatesByCountryId(userDetailResponse?.data?.countryId);
    }
    if (userDetailResponse?.data?.stateId) {
      getCitiesByStateId(userDetailResponse?.data?.stateId);
    }
  }, [userDetailResponse?.data]);

  useEffect(() => {
    () => formik.resetForm();
  }, []);

  const onManagementRoleSelect = (event) => {
    formik.setFieldValue(USER_STATE.MANAGEMENT_ROLE, event.target.value);
  };
  const onCollectionRoleSelect = (columnName, newValue) => {
    formik.setFieldValue(USER_STATE.COLLECTION_ROLE, newValue);
  };

  const onCountrySelect = (event) => {
    getStatesByCountryId(event.target.value);
    formik.setFieldValue(USER_STATE.COUNTRY, event.target.value);
    formik.setFieldValue(USER_STATE.STATE, '');
    formik.setFieldValue(USER_STATE.CITY, '');
    setCitiesData([]);
  };
  const onStateSelect = (event) => {
    getCitiesByStateId(event.target.value);
    formik.setFieldValue(USER_STATE.STATE, event.target.value);
    formik.setFieldValue(USER_STATE.CITY, '');
  };
  const onCitySelect = (event) => {
    formik.setFieldValue(USER_STATE.CITY, event.target.value);
  };

  const onConfirmArchive = () => {
    const archiveUserData = {
      id: formik.values.id,
      status: !formik.values.status,
    };
    updateUserApi(archiveUserData).then((response) => {
      if (response.data) {
        navigate(`/${routeConstants.USERS_ROUTE}?selectedTab=${formik.values.status ? '2' : '1'}`);
      }
    });
    setShowArchiveModal(false);
  };

  const onArchivePress = () => {
    setShowArchiveModal(true);
  };

  const onCancelPress = () => {
    navigate(`/${routeConstants.USER_ROUTE}/${params.id}`);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Breadcrumbs
          icon={<NavigateNextIcon fontSize="medium" />}
          variant="body2"
          underline="hover"
          data={breadcrumbData}
        />
        <Stack spacing={2} direction={'row'} mt={'34px'} alignItems={'center'}>
          <Typography variant={'h1'}>{`${formik.values.firstName} ${formik.values.lastName}`}</Typography>
          <Chip
            label={formik.values.status ? 'Active' : 'Inactive'}
            color={formik.values.status ? 'success' : 'error'}
            variant="outlined"
          />
        </Stack>
        <Box className={classes.basicDetailsContainer}>
          <Typography variant="h2" ml={'24px'}>
            Basic Details
          </Typography>
          <Box>
            <GridContainer rowSpacing={4} columnSpacing={6}>
              <GridItem item xs={12} md={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  InputProps={{
                    'data-testid': 'form-elements',
                  }}
                  error={formik.touched.firstName && formik.errors.firstName ? true : false}
                  helperText={formik.touched.firstName ? formik.errors.firstName : ''}
                  name={USER_STATE.FIRST_NAME}
                  onChange={(e) => formik.setFieldValue(USER_STATE.FIRST_NAME, e.target.value)}
                  onBlur={() => formik.setFieldTouched(USER_STATE.FIRST_NAME)}
                  value={formik.values.firstName}
                  id={USER_STATE.FIRST_NAME}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  InputProps={{
                    'data-testid': 'form-elements',
                  }}
                  error={formik.touched.lastName && formik.errors.lastName ? true : false}
                  helperText={formik.touched.lastName ? formik.errors.lastName : ''}
                  name={USER_STATE.LAST_NAME}
                  onChange={(e) => formik.setFieldValue(USER_STATE.LAST_NAME, e.target.value)}
                  onBlur={() => formik.setFieldTouched(USER_STATE.LAST_NAME)}
                  value={formik.values.lastName}
                  id={USER_STATE.LAST_NAME}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  disabled
                  InputProps={{
                    'data-testid': 'form-elements',
                  }}
                  name={USER_STATE.EMAIL}
                  value={formik.values.email}
                  id={USER_STATE.EMAIL}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  InputProps={{
                    'data-testid': 'form-elements',
                  }}
                  error={formik.touched.phone && formik.errors.phone ? true : false}
                  helperText={formik.touched.phone ? formik.errors.phone : ''}
                  name={USER_STATE.PHONE}
                  onChange={(e) => formik.setFieldValue(USER_STATE.PHONE, e.target.value)}
                  onBlur={() => formik.setFieldTouched(USER_STATE.PHONE)}
                  value={formik.values.phone}
                  id={USER_STATE.PHONE}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <Autocomplete
                  error={
                    (formik.touched.managementRole && formik.errors.managementRole) ||
                    (formik.touched.collectionRole && formik.errors.collectionRole)
                      ? true
                      : false
                  }
                  helperText={
                    (formik.touched.managementRole && formik.errors.managementRole) ||
                    (formik.touched.collectionRole && formik.errors.collectionRole)
                      ? formik.errors.managementRole
                      : ''
                  }
                  multiple={true}
                  options={collectionRoles}
                  getOptionLabel={(option) => option.name || ''}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={formik.values.collectionRole || []}
                  label={'Management Role'} //Changed Label due to business logic, If you want to change please confirm with BA
                  onChange={(_, newValue) => onCollectionRoleSelect(USER_STATE.COLLECTION_ROLE, newValue)}
                  onBlur={() => formik.setFieldTouched(USER_STATE.COLLECTION_ROLE)}
                  id={USER_STATE.COLLECTION_ROLE}
                  disableCloseOnSelect
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <Select
                  defaultValue=""
                  inputProps={{
                    'data-testid': 'form-elements',
                  }}
                  options={managementRoles}
                  error={formik.touched.managementRole && formik.errors.managementRole ? true : false}
                  helperText={formik.touched.managementRole ? formik.errors.managementRole : ''}
                  placeholder={USER_STATE.MANAGEMENT_ROLE}
                  name={USER_STATE.MANAGEMENT_ROLE}
                  onChange={onManagementRoleSelect}
                  onBlur={() => formik.setFieldTouched(USER_STATE.MANAGEMENT_ROLE)}
                  value={formik.values.managementRole || ''}
                  inputLabel={'Collection Role'} //Changed Label due to business logic, If you want to change please confirm with BA
                  id={USER_STATE.MANAGEMENT_ROLE}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <TextField
                  label="Joining Date"
                  variant="outlined"
                  InputProps={{
                    'data-testid': 'form-elements',
                  }}
                  disabled
                  name={USER_STATE.JOINING_DATE}
                  value={formik.values.joiningDate}
                  id={USER_STATE.JOINING_DATE}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <Select
                  defaultValue=""
                  inputProps={{
                    'data-testid': 'form-elements',
                  }}
                  options={countryData}
                  error={formik.touched.country && formik.errors.country ? true : false}
                  helperText={formik.touched.country ? formik.errors.country : ''}
                  name={USER_STATE.COUNTRY}
                  onChange={onCountrySelect}
                  onBlur={() => formik.setFieldTouched(USER_STATE.COUNTRY)}
                  value={formik.values.country || ''}
                  inputLabel={'Country'}
                  id={USER_STATE.COUNTRY}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <Select
                  defaultValue=""
                  inputProps={{
                    'data-testid': 'form-elements',
                  }}
                  options={statesData}
                  error={formik.touched.state && formik.errors.state ? true : false}
                  helperText={formik.touched.state ? formik.errors.state : ''}
                  name={USER_STATE.STATE}
                  onChange={onStateSelect}
                  onBlur={() => formik.setFieldTouched(USER_STATE.STATE)}
                  value={formik.values.state || ''}
                  inputLabel={'State'}
                  id={USER_STATE.STATE}
                />
              </GridItem>
              <GridItem item xs={12} md={6}>
                <Select
                  defaultValue=""
                  inputProps={{
                    'data-testid': 'form-elements',
                  }}
                  options={citiesData}
                  error={formik.touched.city && formik.errors.city ? true : false}
                  helperText={formik.touched.city ? formik.errors.city : ''}
                  name={USER_STATE.CITY}
                  onChange={onCitySelect}
                  onBlur={() => formik.setFieldTouched(USER_STATE.CITY)}
                  value={formik.values.city || ''}
                  inputLabel={'City'}
                  id={USER_STATE.CITY}
                />
              </GridItem>
              <GridItem item xs={6}>
                <TextField
                  label="Address Line 1"
                  variant="outlined"
                  InputProps={{
                    'data-testid': 'form-elements',
                  }}
                  error={formik.touched.address1 && formik.errors.address1 ? true : false}
                  helperText={formik.touched.address1 ? formik.errors.address1 : ''}
                  name={USER_STATE.ADDRESS1}
                  onChange={(e) => formik.setFieldValue(USER_STATE.ADDRESS1, e.target.value)}
                  onBlur={() => formik.setFieldTouched(USER_STATE.ADDRESS1)}
                  value={formik.values.address1}
                  id={USER_STATE.ADDRESS1}
                />
              </GridItem>
              <GridItem item xs={6}>
                <TextField
                  label="Address Line 2"
                  variant="outlined"
                  InputProps={{
                    'data-testid': 'form-elements',
                  }}
                  error={formik.touched.address2 && formik.errors.address2 ? true : false}
                  helperText={formik.touched.address2 ? formik.errors.address2 : ''}
                  name={USER_STATE.ADDRESS2}
                  onChange={(e) => formik.setFieldValue(USER_STATE.ADDRESS2, e.target.value)}
                  onBlur={() => formik.setFieldTouched(USER_STATE.ADDRESS2)}
                  value={formik.values.address2}
                  id={USER_STATE.ADDRESS2}
                />
              </GridItem>
              {isEmployeeIndependentUser && (
                <GridItem item xs={6}>
                  <Autocomplete
                    label={'Regional Manager'}
                    data-testid="form-elements"
                    id={USER_STATE.REGIONAL_MANAGER}
                    name={USER_STATE.REGIONAL_MANAGER}
                    value={formik.values.regionalManager || {}}
                    options={rmUsersData}
                    getOptionLabel={(option) => option.text || ''}
                    onChange={(e, value) => formik.setFieldValue(USER_STATE.REGIONAL_MANAGER, value)}
                    onBlur={() => formik.setFieldTouched(USER_STATE.REGIONAL_MANAGER)}
                    error={formik.touched.regionalManager && formik.errors.regionalManager ? true : false}
                    helperText={formik.touched.regionalManager ? formik.errors.regionalManager : ''}
                    disablePortal
                    labelProp={{ shrink: true }}
                  />
                </GridItem>
              )}
              {isEmployeeIndependentUser && (
                <GridItem item xs={6}>
                  <Autocomplete
                    label={'Accounting User'}
                    data-testid="form-elements"
                    id={USER_STATE.ACCOUNTING_USER}
                    name={USER_STATE.ACCOUNTING_USER}
                    value={formik.values.accountingUser || {}}
                    options={acUsersData}
                    getOptionLabel={(option) => option.text || ''}
                    onChange={(e, value) => formik.setFieldValue(USER_STATE.ACCOUNTING_USER, value)}
                    onBlur={() => formik.setFieldTouched(USER_STATE.ACCOUNTING_USER)}
                    error={formik.touched.regionalManager && formik.errors.accountingUser ? true : false}
                    helperText={formik.touched.regionalManager ? formik.errors.accountingUser : ''}
                    disablePortal
                    labelProp={{ shrink: true }}
                  />
                </GridItem>
              )}
              {isEmployeeIndependentUser && (
                <GridItem item xs={12} md={6}>
                  <TextField
                    label="SU Code"
                    variant="outlined"
                    InputProps={{
                      'data-testid': 'form-elements',
                    }}
                    error={suCodeErrorMessage ? true : false}
                    helperText={suCodeErrorMessage}
                    name={USER_STATE.SU_CODE}
                    onChange={(e) => {
                      formik.setFieldValue(USER_STATE.SU_CODE, e.target.value?.trim());
                      setSUCodeErrorMessage('');
                    }}
                    onBlur={() => formik.setFieldTouched(USER_STATE.SU_CODE)}
                    value={formik.values.suCode}
                    id={USER_STATE.SU_CODE}
                  />
                </GridItem>
              )}
            </GridContainer>
          </Box>
          <Stack
            direction={'row'}
            margin={'52px 24px 0px 24px'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            alignItems={'center'}
          >
            <Stack direction={'row'} spacing={2}>
              <Button type={'submit'} variant="contained">
                {'UPDATE'}
              </Button>
              <Button onClick={onCancelPress}>{'CANCEL'}</Button>
            </Stack>
            <Button
              color={formik.values.status ? 'error' : 'success'}
              onClick={onArchivePress}
              className={classes.errorButton}
            >
              {formik.values.status ? 'ARCHIVE USER' : 'ACTIVATE USER'}
            </Button>
          </Stack>
        </Box>
        <ConfirmationModal
          isOpen={showArchiveModal}
          title="Confirmation"
          msg={`Are you sure you want to ${
            formik.values.status ? 'archive' : 'activate'
          } this user? This process cannot be undone.`}
          buttons={[
            { text: formik.values.status ? 'Archive' : 'Activate', value: true },
            { text: 'Cancel', value: false },
          ]}
          onClick={(value) => {
            if (value) onConfirmArchive();
            else setShowArchiveModal(false);
          }}
        />
      </Box>
    </form>
  );
};

EditUser.displayName = displayName;
export default EditUser;
