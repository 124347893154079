import { InformationPad } from '../../../components/shared';
import dayjs from 'dayjs';
import { formatNumberOrReturnZero } from '../../../utils/common-methods';

const ModemDetailAccordion = ({ modemInfo }) => {
  if (modemInfo) {
    modemInfo = {
      ...modemInfo,
      price: modemInfo?.price || 0,
    };
  }
  const fields = [
    { label: 'Type', value: modemInfo?.type },
    { label: 'MAC Address', value: modemInfo?.MACAddress },

    {
      label: 'Installed Date',
      value: modemInfo?.installedDate ? dayjs(modemInfo?.installedDate).format('MM/DD/YYYY') : modemInfo?.installedDate,
    },
    { label: 'Price', value: `$${formatNumberOrReturnZero(modemInfo?.price)}` },
    { label: 'Login ID', value: modemInfo?.loginId },
    { label: 'Password', value: modemInfo?.password },
    { label: 'Serial Number', value: modemInfo?.serialNumber },
  ];

  return <InformationPad data={fields} />;
};
export default ModemDetailAccordion;
