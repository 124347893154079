import React, { useState, useMemo, useEffect } from 'react';
import SearchBar from '../../../components/SearchBar';
import useStyles from '../style';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import { Breadcrumbs, GridContainer, GridItem, InformationField, NoContent } from '../../../components/shared';
import { Container, Box, Chip, Typography, Stack, Paper, Button } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { sortIcon } from '../../../assets/images/icons';

import FILTER_CONSTANTS from '../../../constants/FilterConstants';
import SORT_CONSTANT, { ASSET_ID_ASC, COLLECTION_SORT_PROPERTY } from '../../../constants/SortConstants';
import { MESSAGE } from '../../../constants/message';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';

import { useNavigate, useParams } from 'react-router-dom';
import {
  checkNumberIsNaNAndInfinity,
  formatNumberOrReturnZero,
  getAddress,
  getCurrency,
  getDateFormat,
  handledSpecialCharacterRegex,
  roundOffValue,
  setPlayCardSummaryValToDecimal,
  sortArrayOfObjects,
} from '../../../utils/common-methods';

import SublocationMachinesForm from '../AddCollection/SublocationMachinesForm';
import { FIELD_NAME, METER_TYPE } from '../../../constants/MeterTypeFieldMap';
import {
  getDispensedValue,
  getPlayCardSalesRevenueInUsd,
  getRevenueInLocalCurrency,
  getTokenChangerRevenueAndDispensedValue,
  getTokenChangerRevenueForApproveDetails,
} from '../../../utils/collection-methods';
import {
  useGetCollectionsByTransactionIdQuery,
  useReOpenCollectionsByTransactionIdMutation,
} from '../../../services/CollectionService/CollectionService';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPlayCards,
  resetState as resetPlaycardState,
  setPlayCardSalesSummary,
} from '../../../redux-slice/playcardsSummary';
import { CURRENCY_CONSTANT, DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { ROLE } from '../../../constants/roles';
import { useHasRoles } from '../../../hooks';
import { setLocationPaysTax } from '../../../redux-slice/reconciliationCollection';

const displayName = 'Approved Collection Detail';

const ApprovedCollectionDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSuperAdmin = useHasRoles([ROLE.SUPER_ADMIN_IT]);
  const { playCards, playcardSalesSummary } = useSelector((state) => state.playCard);
  const [locationCurrency, setLocationCurrency] = useState({});
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [sort, setSort] = useState(ASSET_ID_ASC);

  const { DRAWER_TYPES, TITLE } = FILTER_CONSTANTS;
  const {
    APPROVED_COLLECTION: { APPROVED_COLLECTION_DETAIL, APPROVED_COLLECTION_LIST },
  } = BREADCRUMB_NAMES;
  const breadcrumbData = [
    {
      text: APPROVED_COLLECTION_LIST,
      redirection: `${routeConstants.COLLECTIONS_ROUTE}?selectedTab=3`,
    },
    { text: APPROVED_COLLECTION_DETAIL },
  ];

  const {
    currentData: latestData,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetCollectionsByTransactionIdQuery({ transactionId: params.id });

  useEffect(() => {
    dispatch(resetPlaycardState());
    return () => dispatch(resetPlaycardState());
  }, []);

  const data = useMemo(() => {
    const exchangeRate = latestData?.approvedExchangeRate || DEFAULT_EXCHANGE_RATE;

    let totalTicketDispensed = 0,
      totalTokenRevenueCollected = 0,
      totalTokenCollected = 0,
      averageTokenValue = 0, // TODO: read averageTokenValue from DB?
      totalPrizeDispensed = 0,
      totalTokenDispensed = 0;
    let tableData = {};
    if (isSuccess && latestData) {
      const assetMeters = latestData?.sublocations?.flatMap((el) => el.assets)?.flatMap((el) => el?.assetMeters);
      assetMeters.map((assetMeter) => {
        if (assetMeter?.meter?.name === METER_TYPE?.TICKET) {
          const ticketDispensed = getDispensedValue(
            assetMeter[FIELD_NAME.CURRENT_READING],
            assetMeter[FIELD_NAME.PRIOR_READING],
          );
          totalTicketDispensed += ticketDispensed;
        }
        if (assetMeter?.meter?.name === METER_TYPE?.TOKEN) {
          const tokenCollected = assetMeter[FIELD_NAME.TOKEN_COLLECTED];
          totalTokenCollected += checkNumberIsNaNAndInfinity(tokenCollected);
        }
        if (assetMeter?.meter?.name === METER_TYPE?.PRIZE) {
          const prizeDispensed = getDispensedValue(
            assetMeter[FIELD_NAME.CURRENT_READING],
            assetMeter[FIELD_NAME.PRIOR_READING],
          );
          totalPrizeDispensed += prizeDispensed;
        }
        if (assetMeter?.meter?.name === METER_TYPE.TOKEN_CHANGER) {
          const { tokenDispensed } = getTokenChangerRevenueAndDispensedValue(
            assetMeter[FIELD_NAME.CURRENT_READING],
            assetMeter[FIELD_NAME.PRIOR_READING],
            assetMeter,
          );
          totalTokenRevenueCollected = getTokenChangerRevenueForApproveDetails(
            totalTokenRevenueCollected,
            assetMeter[FIELD_NAME.CURRENT_READING],
            assetMeter[FIELD_NAME.PRIOR_READING],
            assetMeter,
            exchangeRate,
          );
          totalTokenDispensed += tokenDispensed;
        }
      });
      averageTokenValue = roundOffValue(Number(totalTokenRevenueCollected / totalTokenCollected), 4);
    }
    tableData.totalTicketDispensed = totalTicketDispensed;
    tableData.totalTokenCollected = totalTokenCollected;
    tableData.totalPrizeDispensed = totalPrizeDispensed;
    tableData.averageTokenValue = averageTokenValue;
    tableData.totalTokenDispensed = totalTokenDispensed;

    if (searchText) {
      const searchGloballyRegex = new RegExp(searchText, 'i');
      const data_filter = latestData?.sublocations.filter(function (element) {
        return element.assets.some(function (subElement) {
          return (
            String(subElement.id).match(searchGloballyRegex) ||
            String(subElement?.legacyAssetId).match(searchGloballyRegex) ||
            subElement?.title?.match(searchGloballyRegex)
          );
        });
      });

      const filterAssets = data_filter.map(function (element) {
        return {
          ...element,
          assets: element.assets.filter(function (subElement) {
            return (
              String(subElement.id).match(searchGloballyRegex) ||
              String(subElement?.legacyAssetId).match(searchGloballyRegex) ||
              subElement?.title?.match(searchGloballyRegex)
            );
          }),
        };
      });

      tableData = { ...tableData, ...latestData, sublocations: filterAssets };

      return tableData;
    }
    if (sort) {
      const sublocations = latestData?.sublocations?.map((sub) => {
        const sortedAssets = sortArrayOfObjects(sub?.assets, COLLECTION_SORT_PROPERTY[sort]);
        return { ...sub, assets: sortedAssets };
      });
      tableData = { ...tableData, ...latestData, sublocations: sublocations };
      return tableData;
    } else {
      tableData = { ...tableData, ...latestData };
      return tableData;
    }
  }, [isSuccess, latestData, sort, searchText]);

  useEffect(() => {
    if (latestData?.sublocations && isSuccess) {
      const sublocations = latestData?.sublocations || [];
      const currency = latestData?.currency;
      const approvedExchangeRate = latestData?.approvedExchangeRate;
      let storedPlayCardData = {};
      sublocations?.map((item) => {
        const { id: sublocationId, locPaysTaxFlag } = item;
        dispatch(setLocationPaysTax({ [sublocationId]: locPaysTaxFlag }));

        const { playCardSumDetails } = item;
        setLocationCurrency({
          id: currency?.id || null,
          abbreviation: currency?.abbreviation || null,
          exchangeRate: approvedExchangeRate,
        });
        if (playCardSumDetails) {
          storedPlayCardData = Object.assign(storedPlayCardData, { [sublocationId]: playCardSumDetails });
        }
      });
      dispatch(setPlayCards(setPlayCardSummaryValToDecimal(storedPlayCardData)));
      const totalPlayCardSales = getPlayCardSalesRevenueInUsd(storedPlayCardData);
      dispatch(setPlayCardSalesSummary(roundOffValue(totalPlayCardSales)));
    }
  }, [isSuccess, latestData]);

  const searchHandler = (params) => {
    const patternValue = handledSpecialCharacterRegex(params);
    setSearchText(patternValue);
  };

  const handleSort = (e) => {
    setSort(e.target.value);
  };

  const onReOpenModalClickHandler = (value) => {
    if (value === MESSAGE.REOPEN) {
      reOpenHandler();
    } else {
      setShowDeleteModal(false);
    }
  };

  const [reOpenReconciliationApi] = useReOpenCollectionsByTransactionIdMutation();

  const reOpenHandler = () => {
    setShowDeleteModal(false);
    reOpenReconciliationApi(params.id).then((response) => {
      if (response.data) {
        setShowDeleteModal(false);
        setTimeout(() => {
          navigate(
            `/${routeConstants.COLLECTIONS_ROUTE}/${routeConstants.RECONCILIATION_COLLECTION_ROUTE}/${params.id}`,
            {
              redirect: true,
            },
          );
        }, 1000);
      }
    });
  };

  return (
    <React.Fragment>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {!(isLoading || isFetching) && (
        <Container maxWidth="xl" disableGutters>
          <Box mb={3}>
            <Breadcrumbs
              icon={<NavigateNext fontSize="medium" />}
              variant="body2"
              underline="hover"
              data-testid="breadcrumb"
              data={breadcrumbData}
            />
          </Box>
          <Box mb={3}>
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <Typography variant="h1" data-testid="locationName" textAlign="center">
                {data?.location?.locationName}
              </Typography>
              <Chip
                label={data?.location?.status ? 'Active' : 'Inactive'}
                color={data?.location?.status ? 'success' : 'error'}
                variant="outlined"
              />
            </Stack>
          </Box>
          <Box mb={1} component={Paper}>
            <GridContainer data-testid="info-pad" spacing={2} px={3} py={1} display="flex" justifyContent="flex-start">
              <GridItem xs={12} sm={4} md={3} lg={2}>
                <InformationField value={'TYPE'} variant="subtitle2" />
                <InformationField value={data?.location?.locationType?.name} variant="subtitle1" />
              </GridItem>
              <GridItem xs={12} sm={4} md={3} lg={2}>
                <InformationField value={MESSAGE.TOTAL_ASSET} variant="subtitle2" />
                <InformationField value={data?.totalAssets} variant="subtitle1" />
              </GridItem>
              <GridItem xs={12} sm={4} md={3} lg={2}>
                <InformationField value={MESSAGE.SUBMISSION_DATE.toUpperCase()} variant="subtitle2" />
                <InformationField value={getDateFormat(data?.submissionDate)} variant="subtitle1" />
              </GridItem>
              <GridItem xs={12} sm={4} md={3} lg={2}>
                <InformationField value={MESSAGE.COLLECTION_LOCATION_CITY} variant="subtitle2" />
                <InformationField value={data?.location?.city?.name} variant="subtitle1" />
              </GridItem>
              <GridItem xs={12} sm={4} md={3} lg={2}>
                <InformationField value={MESSAGE.COLLECTION_LOCATION_ADDRESS} variant="subtitle2" />
                <InformationField value={getAddress(data?.location)} variant="subtitle1" />
              </GridItem>
              {locationCurrency?.abbreviation !== CURRENCY_CONSTANT.USD && (
                <GridItem xs={12} sm={4} md={3} lg={2}>
                  <InformationField value={MESSAGE.COLLECTION_EXCHANGE_RATE} variant="subtitle2" />
                  <InformationField
                    value={locationCurrency?.exchangeRate || DEFAULT_EXCHANGE_RATE}
                    variant="subtitle1"
                  />
                </GridItem>
              )}
            </GridContainer>
          </Box>
          <Box component={Paper} className={classes.accordion}>
            <Box className={classes.searchFilterWrap}>
              <Box className={classes.searchWrap}>
                <SearchBar onSearch={searchHandler} isEnterDisabled />
              </Box>
              <Box pl={3}>
                <NewFilterDrawer
                  name={TITLE.SORT_BY.toUpperCase()}
                  title={TITLE.SORT_BY}
                  drawerType={DRAWER_TYPES.SORT_DRAWER}
                  startIcon={<>{sortIcon}</>}
                  onSortSelect={handleSort}
                  selectedSort={sort}
                  sortData={SORT_CONSTANT.ADD_COLLECTION_SORT}
                />
              </Box>
            </Box>
            {data?.sublocations?.length <= 0 && (
              <NoContent
                title="No Asset Found"
                desc={<>No results found, please try changing the search criteria.</>}
              />
            )}
            {data?.sublocations?.map((sublocation) => (
              <SublocationMachinesForm
                averageTokenValue={data.averageTokenValue}
                key={sublocation?.id}
                sublocation={sublocation}
                isApproved
                locationCurrency={locationCurrency}
              />
            ))}
          </Box>

          {data?.sublocations?.length > 0 && (
            <Box mt={3} component={Paper}>
              <GridContainer
                data-testid="info-pad"
                spacing={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <GridItem xs={10}>
                  <GridContainer data-testid="info-pad" display="flex" justifyContent="flex-start" alignItems="center">
                    {locationCurrency?.abbreviation !== CURRENCY_CONSTANT.USD && (
                      <GridItem xs={6} sm={2}>
                        <Box>
                          <InformationField
                            value={`${MESSAGE.COLLECTION_TOTAL_REVENUE} (${locationCurrency?.abbreviation || ''})`}
                            variant="subtitle7"
                          />
                        </Box>
                        <InformationField
                          value={`${getCurrency(
                            locationCurrency.abbreviation || CURRENCY_CONSTANT.USD,
                          )} ${roundOffValue(
                            getRevenueInLocalCurrency(data?.totalRevenue || 0, locationCurrency.exchangeRate),
                          )}`}
                          variant="subtitle4"
                        />
                      </GridItem>
                    )}
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField
                          value={`${MESSAGE.COLLECTION_TOTAL_REVENUE} (${CURRENCY_CONSTANT.USD})`}
                          variant="subtitle7"
                          data-testid="totalRevenueTitle"
                        />
                      </Box>
                      <InformationField
                        value={`$ ${formatNumberOrReturnZero(data?.totalRevenue || 0)}`}
                        variant="subtitle4"
                        data-testid="totalRevenue"
                      />
                    </GridItem>
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.TOTAL_ASSET} variant="subtitle7" />
                      </Box>
                      <InformationField value={data?.totalAssets} variant="subtitle4" />
                    </GridItem>
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TICKET_DISPENSED} variant="subtitle7" />
                      </Box>
                      <InformationField value={data.totalTicketDispensed} variant="subtitle4" />
                    </GridItem>
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TOTAL_TOKEN_COLLECTED} variant="subtitle7" />
                      </Box>
                      <InformationField value={data.totalTokenCollected} variant="subtitle4" />
                    </GridItem>
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TOTAL_TOKEN_DISPENSED} variant="subtitle7" />
                      </Box>
                      <InformationField value={data?.totalTokenDispensed} variant="subtitle4" />
                    </GridItem>
                    {data.averageTokenValue > 0 && (
                      <GridItem xs={6} sm={2}>
                        <Box>
                          <InformationField
                            value={`${MESSAGE.AVERAGE_TOKEN_VALUE} (${CURRENCY_CONSTANT.USD})`}
                            variant="subtitle7"
                          />
                        </Box>
                        <InformationField value={`$ ${data.averageTokenValue}`} variant="subtitle4" />
                      </GridItem>
                    )}
                    <GridItem xs={6} sm={2}>
                      <Box>
                        <InformationField value={MESSAGE.COLLECTION_TOTAL_PRIZE_DISPENSED} variant="subtitle7" />
                      </Box>
                      <InformationField value={data.totalPrizeDispensed} variant="subtitle4" />
                    </GridItem>
                    {JSON.stringify(playCards).length > 2 && (
                      <GridItem xs={6} sm={2}>
                        <Box>
                          <InformationField
                            value={`${MESSAGE.PLAYCARD_SALES_SUMMARY} (${CURRENCY_CONSTANT.USD})`}
                            variant="subtitle7"
                          />
                        </Box>
                        <InformationField
                          value={`$ ${formatNumberOrReturnZero(playcardSalesSummary)}`}
                          variant="subtitle4"
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Box>
          )}
          {isSuperAdmin && (
            <React.Fragment>
              <Box mt={3}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Re-open
                </Button>
              </Box>
              <ConfirmationModal
                isOpen={showDeleteModal}
                title={MESSAGE.CONFIRMATION}
                msg={MESSAGE.REOPEN_APPROVED_COLLECTION}
                buttons={[
                  { text: MESSAGE.REOPEN, value: MESSAGE.REOPEN },
                  { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
                ]}
                onClick={onReOpenModalClickHandler}
              />
            </React.Fragment>
          )}
        </Container>
      )}
    </React.Fragment>
  );
};

ApprovedCollectionDetail.displayName = displayName;
export default ApprovedCollectionDetail;
