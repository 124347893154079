import { useEffect } from 'react';
import { Box, Typography, Stack, Chip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { Accordion, InformationPad, PositionedMenu } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { checkValueNotNullUndefinedBlank, formatPhNo10Digit, getUserName } from '../../../utils/common-methods';
import { USER_DETAIL_LABEL } from '../../../constants/accordionFields';
import { ROLE } from '../../../constants/roles';

export const UserInfoUpdateForm = ({ employeeDetails = {}, disabled }) => {
  const {
    firstName = '',
    lastName = '',
    roles: { managementRole = [], nmRoles = [] } = {},
    status = false,
    email = '',
    phone1 = '',
    hireDate = '',
    country = {},
    state = {},
    city = {},
    address1 = '',
    address2 = '',
    regionalManager = {},
    accountingUser = {},
    vendorUser = {},
  } = employeeDetails;

  const navigate = useNavigate();
  const params = useParams();
  const isEmployeeIndependentUser = Boolean(managementRole?.find((el) => el?.title === ROLE.INDEPENDENT_REPS));
  const otherFields = [
    { label: USER_DETAIL_LABEL.REGIONAL_MANAGER, value: getUserName(regionalManager) },
    { label: USER_DETAIL_LABEL.ACCOUNTING_USER, value: getUserName(accountingUser) },
    { label: USER_DETAIL_LABEL.SU_CODE, value: vendorUser?.vendorCode ? vendorUser?.vendorCode : '-' },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userInfoDispFields = [
    {
      label: USER_DETAIL_LABEL.ROLES,
      value: [...managementRole, ...nmRoles]
        .map((eachRole) => (eachRole.title ? eachRole.title : ''))
        .toString()
        .replace(/,/g, ', '),
    },
    { label: USER_DETAIL_LABEL.EMAIL_ID, value: email },
    { label: USER_DETAIL_LABEL.PHONE, value: formatPhNo10Digit(phone1) },
    { label: USER_DETAIL_LABEL.JOINING_DATE, value: hireDate },
    { label: USER_DETAIL_LABEL.COUNTRY, value: country?.name },
    { label: USER_DETAIL_LABEL.STATE, value: state?.name },
    { label: USER_DETAIL_LABEL.CITY, value: city?.name },
    { label: USER_DETAIL_LABEL.ADDRESS_LINE_1, value: address1 },
    { label: USER_DETAIL_LABEL.ADDRESS_LINE_2, value: address2 },
  ];

  return (
    <Box>
      <Stack mb={4} direction={'row'} alignItems="center" spacing={2}>
        <Typography variant="h1" data-testid="locationName">
          {firstName}
          {checkValueNotNullUndefinedBlank(firstName) ? ' ' : ''}
          {lastName}
        </Typography>
        <Chip label={status ? 'Active' : 'Inactive'} color={status ? 'success' : 'error'} variant="outlined" />
      </Stack>
      <Box>
        <Accordion
          defaultExpanded
          marginBottom={3}
          title="Basic Details"
          content={
            <InformationPad
              data={isEmployeeIndependentUser ? [...userInfoDispFields, ...otherFields] : userInfoDispFields}
            />
          }
          subComponent={
            <PositionedMenu
              menuItemData={[
                {
                  btnName: 'Edit',
                  btnClickFunc: () =>
                    navigate(`/${routeConstants.USER_ROUTE}/${routeConstants.EDIT_USER_ROUTE}/${params.id}`),
                },
              ]}
              disabled={disabled}
            />
          }
        />
      </Box>
    </Box>
  );
};
