import React, { useEffect, useState } from 'react';
import { Button, GridContainer, GridItem, Modal, Select, PercentTextField } from '../../../components/shared';
import { Box, Grid, MenuItem } from '@mui/material';
import { MESSAGE } from '../../../constants/message';
import { RATE_TYPE_OPTIONS, RATE_TYPES } from '../../../constants/RatesConstants';
import { PerformantDecimalField } from '../../../components/shared/PerformantDecimalField/PerformantDecimalField';

const EditUserRatesModal = (props) => {
  const { onClose = () => {}, onConfirm = () => {}, selectedSublocation, employeeDetails = {} } = props;
  const [showRatesModal, setShowRatesModal] = useState(false);
  const [sublocations, setSublocations] = useState([]);
  const [disableConfirm, setDisableConfirm] = useState(true); // Initially, disable Confirm button
  const { firstName, lastName } = employeeDetails || {};

  useEffect(() => {
    if (selectedSublocation && selectedSublocation.length > 0) {
      setShowRatesModal(true);
      setSublocations(selectedSublocation);
    }
  }, [selectedSublocation]);

  const handleClose = () => {
    onClose();
    setShowRatesModal(false);
  };

  const handleConfirm = () => {
    onConfirm(sublocations);
    setShowRatesModal(false);
  };

  const handlePercentChange = (e) => {
    const { name, value } = e && e.target ? e.target : e;

    const [field, index] = name.split('_');
    const tempSublocations = [...sublocations];

    if (field === 'repRate' || field === 'repFee') {
      tempSublocations[index][field] = value; // Allow only 2 decimal places
    } else if (field === 'type') {
      if (value === RATE_TYPES.None) {
        tempSublocations[index].repFee = '0';
        tempSublocations[index].repRate = '0';
      }
      tempSublocations[index].type = value;
    }

    setSublocations(tempSublocations);
    validateSublocations(tempSublocations); // Validate after any change
  };

  const validateSublocations = (sublocations) => {
    let isValid = true;

    for (let sublocation of sublocations) {
      const { repRate, repFee, type } = sublocation;

      // Validation logic based on type
      if (type === RATE_TYPES.RATE_ONLY && (!repRate || repRate <= 0)) {
        isValid = false;
        break;
      }
      if (type === RATE_TYPES.FIXED_ONLY && (!repFee || repFee <= 0)) {
        isValid = false;
        break;
      }
      if (type === RATE_TYPES.BOTH && (!repRate || repRate <= 0 || !repFee || repFee <= 0)) {
        isValid = false;
        break;
      }
      if (type === RATE_TYPES.None && (repFee > 0 || repRate > 0)) {
        isValid = false;
        break;
      }
    }

    setDisableConfirm(!isValid); // Disable Confirm button if validation fails
  };

  return (
    <Modal
      open={showRatesModal}
      onClose={handleClose}
      title={`Edit Rates for ${firstName} ${lastName}`}
      titleVariant="subtitle10"
      actionButtons={
        <Box my={3}>
          <Button variant="contained" size="large" onClick={handleConfirm} disabled={disableConfirm}>
            {MESSAGE.CONFIRM}
          </Button>
          <Button size="large" onClick={handleClose} variant={'text'}>
            {MESSAGE.CANCEL}
          </Button>
        </Box>
      }
      maxWidth={'md'}
    >
      {sublocations?.map((sublocation, index) => {
        let { id, repRate, repFee, name, type } = sublocation;

        return (
          <Grid key={id}>
            <div> {name}:</div>
            <GridContainer rowSpacing={2} gap={2}>
              <GridItem>
                <PercentTextField
                  label={'Rate'}
                  value={repRate}
                  name={`repRate_${index}`}
                  onChange={handlePercentChange}
                  error={
                    ((type === RATE_TYPES.RATE_ONLY || type === RATE_TYPES.BOTH) && (!repRate || repRate <= 0)) ||
                    (type === RATE_TYPES.None && Number(repRate) > 0)
                  } // Display error for invalid input
                  helperText={
                    (type === RATE_TYPES.RATE_ONLY || type === RATE_TYPES.BOTH) && (!repRate || repRate <= 0)
                      ? 'Rate must be greater than 0'
                      : type === RATE_TYPES.None && Number(repRate) > 0
                      ? 'Rate must be 0'
                      : ''
                  }
                  disabled={type === RATE_TYPES.None}
                />
              </GridItem>
              <GridItem>
                <PerformantDecimalField
                  useFormik={false}
                  label={'Fee'}
                  value={repFee}
                  name={`repFee_${index}`}
                  onChange={handlePercentChange}
                  onBlur={handlePercentChange}
                  error={
                    ((type === RATE_TYPES.FIXED_ONLY || type === RATE_TYPES.BOTH) && (!repFee || repFee <= 0)) ||
                    (type === RATE_TYPES.None && Number(repFee) > 0)
                  } // Display error for invalid input
                  helperText={
                    (type === RATE_TYPES.FIXED_ONLY || type === RATE_TYPES.BOTH) && (!repFee || repFee <= 0)
                      ? 'Fee must be greater than 0'
                      : type === RATE_TYPES.None && Number(repFee) > 0
                      ? 'Fee must be 0'
                      : ''
                  }
                  disabled={type === RATE_TYPES.None}
                />
              </GridItem>
              <GridItem>
                <Select
                  name={`type_${index}`}
                  labelId="select-label"
                  value={type}
                  inputLabel="Type"
                  onChange={handlePercentChange}
                  sx={{
                    '& .MuiInputBase-input': {
                      marginRight: '10px',
                      minWidth: '150px', // Example styling
                    },
                  }}
                >
                  {RATE_TYPE_OPTIONS?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </GridItem>
            </GridContainer>
          </Grid>
        );
      })}
    </Modal>
  );
};

export default EditUserRatesModal;
